<template>
  <div data-role="appbar" class="pos-absolute bg-red fg-white">
    <a class="app-bar-item d-block d-none-lg" id="paneToggle"
      ><span class="mif-menu"></span
    ></a>
    <div class="siteTitle">
      <h6 class="text-light m-0 fg-white pl-3" style="line-height: 52px">
        Elektronation Klimacomputer <small class="fg-gray">High-End SCADA Interface</small>
      </h6>
    </div>
    <div class="app-bar-container ml-auto">
      <router-link to="/tablealarm">
        <a href="#" class="app-bar-item">
          <span class="mif-weather"></span>
          <span class="badge bg-green fg-white mt-2 mr-1">4</span>
        </a>
      </router-link>
      <router-link to="/tablealarm">
        <a href="#" class="app-bar-item">
          <span class="mif-warning fg-yellow"></span>
          <span class="badge bg-darkRed fg-white mt-2 mr-1">{{
            this.$store.getters.getAlarm
          }}</span>
        </a>
      </router-link>
      <div class="app-bar-container">
        <a href="#" class="app-bar-item">
          <img src="@/assets/images/enat.png" class="avatar" />
          <span class="ml-2 app-bar-name">enat</span>
        </a>
        <div
          class="user-block shadow-1"
          data-role="collapse"
          data-collapsed="true"
        >
          <div class="bg-darkRed fg-white p-2 text-center">
            <img src="@/assets/images/enat.png" class="avatar" />
            <div class="h4 mb-0">enat</div>
            <div>Maschinenmeister</div>
          </div>
          <div
            class="bg-crimson d-flex flex-justify-between flex-equal-items p-2"
          >
            <input type="checkbox" data-role="switch" data-caption="Alarme" />
            <input type="checkbox" data-role="switch" data-caption="Switch" />
          </div>
          <div
            class="
              bg-crimson
              d-flex
              flex-justify-between flex-equal-items
              p-2
              bg-light
            "
          >
            <button class="button mr-1">Profil</button>
            <button class="button ml-1">Abmelden</button>
          </div>
        </div>
      </div>
      <a
        v-if="editMode"
        href="#"
        onclick="toggleCharms();"
        id="toggleCharms"
        class="app-bar-item"
        data-bind="click: toggleEditing"
      >
        <span id="toggleCharmsSpan" class="mif-cogs"></span>
      </a>
      <Button class="app-bar-item" v-if="editMode" icon="mif-cogs" @click="editEvent" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  setup() {},
  name: "titleBar",
  props: {
    msg: String,
    title: String,
    name2: String,
    desc2: String,
    dasObject: Object,
  },
  data: function () {
    return {
    };
  },
  methods: {
    editEvent: function () {
      store.dispatch("setPageData", {'isEdit': !this.isEdit});
    },
  },
  computed: {
    editMode: function () {
      if (store.getters.getPageData.editMode === true) {
        return true;
      } else {
        return false;
      }
    },
    isEdit: function () {
      if (store.getters.getPageData.isEdit === true) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {

  },
})
</script>


<style scoped>
  @media (max-width: 767px) {
    .siteTitle {
      display: none !important;
    }
  }
</style>